import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button/Button';
import styles from './DataCollectionBankStatementFrame.css';

export const DataCollectionSupplyManuallyModal = (props) => {
  return (
    <div>
      <p>We can&#39;t pre-fill your expense data if you supply manually</p>
      <div className={styles.modalButtonGroup}>
        <Button theme='linkButtonTheme' onClick={props.cancel}>
          Go back and connect
        </Button>
        <Button
          theme='buttonNext'
          className={styles.blockBtn}
          onClick={props.confirm}
        >
          Yes, supply manually
        </Button>
      </div>
    </div>
  );
};

DataCollectionSupplyManuallyModal.propTypes = {
  confirm: PropTypes.func,
  cancel: PropTypes.func,
};
